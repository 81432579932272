@import './colors';

// screensize breakpoints
$sp-min: 320px;
$sp-max: 743px;
$tl-min: 744px;
$tl-max: 1024px;
$pc-min: 1025px;
$pc-max: 1200px;
$sp-min-width: 360px;

// Font family
$font-body: Noto Sans CJK JP;
$font-ios: ヒラギノ角ゴ Pro W3;
$font-android: Noto Sans CJK JP;
$font-en-browser: Rakuten Sans UI;

// Font scale
$font-large: 26px;
$font-solar: 24px;
$font-jupiter: 22px;
$font-saturn: 20px;
$font-uranus: 18px;
$font-neptune: 16px;
$font-venus: 14px;
$font-mars: 12px;
$font-terra: 10px;
$font-luna: 8px;

// Line height
$line-height-title: 1.25;
$line-height-title-sp: 1.27;
$line-height-sub-title: 1.2;
$line-height-sub-title-sp: 1.22;
$line-height-category: 1.5;
$line-height-label: 1.43;
$line-height-sub-label: 1.5;
$line-height-body: 1.43;
$line-height-sub-body: 1.5;
$line-height-title-h1: 26px;

// spacing
$spacing-xl: 32px;
$spacing-l: 24px;
$spacing-m: 16px;
$spacing-tw: 12px;
$spacing-base: 8px;
$spacing-s: 4px;
$spacing-mini: 2px;

// height
$height-xl: 32px;
$height-l: 24px;
$height-m: 16px;
$height-tw: 12px;
$height-base: 8px;
$height-s: 4px;
$height-title: 90px;

// Border radius
$border-radius: 4px;

// z-index
$zIndex-overlay1: 1; // Ex. Carousel's arrow, overlap border
$zIndex-overlay2: 2;
$zIndex-overlay3: 3;
$zIndex-adsExpandable: 150;
$zIndex-adsFloating: 250;
$zIndex-popup: 350;
$zIndex-floating: 450;
$zIndex-dropdown: 550; // Ex. dropdown menu, selectbox
$zIndex-modal: 650; // Ex. Fullscreen carousel, FullscreenDialog
$z-index-header: 999;
$z-index-sidebar: 9999;

// dialog
$dialog-header-height: 50px;

// image
$image-width: 360px;
$image-height: 230px;

// coupon
$coupon-max-width: 343px;
$coupon-min-width: 248px;
