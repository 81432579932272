//Title
// @mixin font-title {
//   font-size: $font-solar;
//   font-weight: bold;
//   line-height: $line-height-title;

//   @include media(sp) {
//     font-size: $font-jupiter;
//     line-height: $line-height-title-sp;
//   }
// }

//Title
@mixin font-title {
  font-size: $font-saturn;
  font-weight: bold;  
  line-height: $line-height-sub-title;

  @include media(sp) {
    font-size: $font-uranus;
    line-height: $line-height-sub-title;
  }
}

// Sub-Title
@mixin font-sub-title {
  font-size: $font-neptune;
  font-weight: bold;
  line-height: $line-height-category;
}

// Category
@mixin font-category {
  font-size: $font-venus;
  font-weight: bold;
  line-height: $line-height-label;
}

// Label
@mixin font-label {
  font-size: $font-mars;
  font-weight: bold;
  line-height: $line-height-sub-title;
}

// Body
@mixin font-body {
  font-size: $font-venus;
  line-height: $line-height-body;
}

// Sub Body
@mixin font-sub-body {
  font-size: $font-mars;
  line-height: $line-height-sub-body;
}