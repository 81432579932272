
//Main
$primary: #BF0000;
$p-variant: #8C0000;
$secondary: #0178D4;
$s-variant: #134D84;
$tertiary: #333333;
$text-link: #1D54A7;

//Grey Scale
$dark-grey: #333333;
$grey: #606060;
$sub-grey: #686868;
$disable: #898989;
$midium-grey: #B6B6B6;
$border: #D1D1D1;
$light-grey: #EBEBEB;
$disable-bg:#DFDFDF;
$background: #F7F7F7;
$white: #FFFFFF;

//Alert
$success: #047205;
$success-bg: #EBF7EC;
$info: #006497;
$info-bg: #EBF7FE;
$warn: #7D6E00;
$warn-bg: #EBF7EC;
$danger: #DF0101;
$danger-bk: #FFF0F0;

//Illustration
$illustration-bg: #FFF4D2;
