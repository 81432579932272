@import 'styles/utility.scss';
@import 'styles/base.scss';

.stepHeader {
  min-height: 143px;
  padding: $spacing-xl 0;
}

.btn_wrapper {
  width: 100%;
  display: flex;
}