// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
 html {
  -webkit-text-size-adjust:100%;
  box-sizing: border-box;
  font-family: $font-ios, $font-android, $font-body, arial, sans-serif;
  &:lang(en){
    font-family: $font-ios, $font-android, $font-en-browser, arial, sans-serif;
  }
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @include font-body;
  color: $dark-grey;
  margin: auto;
  max-width: 960px;
}

h1 {
  @include font-title;
}

h2 {
  @include font-sub-title;
}

h3 {
  @include font-category;
  border-left: 4px solid $primary;
  padding: 0 0 0 $spacing-base;
  margin: 0 $spacing-m;
}

h4 {
  @include font-label;
}

h4:before {
  content:'';
  display:inline-block; 
  background: $primary;
  height: 5px;
  line-height: 14px;
  margin: 0 $spacing-s 2px 0;
  vertical-align: middle;
  width: 5px;
}

